/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import SVG from 'react-inlinesvg';
import slugify from 'slugify';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';
import { CategoryIconMapper } from '../../utils/book-utils';

const BookCategoryHeader = ({ category, backLink, label }) => {
  const normalizedCategory = slugify(category.toLowerCase());
  const iconDimensions = {
    height: '50px',
    width: '50px',
  };

  const config = resolveConfig(tailwindConfig);
  const {
    theme: {
      colors: { gray },
    },
  } = config;

  function ViewAllButton({ isMobile }) {
    const style = isMobile ? 'mt-4' : 'absolute';

    return (
      <Link
        to={backLink}
        className={`px-4 py-2 bg-gray-200 rounded shadow hover:shadow-md ${style} font-semibold uppercase font-sans text-xs text-gray-600`}
        css={css`
          background: var(--bgRaised);
          right: 0;
          bottom: 0;
          border-color: var(--textNormal);
        `}
      >
        <i className="fa fa-chevron-left inline-block mr-2" />
        View All Books
      </Link>
    );
  }

  return (
    <div
      className="border-b border-t py-2"
      css={css`
        border-color: var(--border);
      `}
    >
      {' '}
      <div className="container relative">
        <div className="flex items-center justify-center">
          <div className="flex flex-col justify-center items-center">
            <SVG
              cacheRequests
              description={category}
              preProcessor={(code) => code.replace(/fill=".*?"/g, `fill="${gray[600]}"`)}
              src={CategoryIconMapper(normalizedCategory)}
              title={category}
              style={iconDimensions}
              className="mt-5 mb-2"
            />{' '}
            <h2 className="inline-block m-0 font-display">{category}</h2>
            <span className="font-display text-sm mb-4 md:mb-2">{label || 'Category'}</span>{' '}
            <div className="block md:hidden">
              <ViewAllButton isMobile />
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <ViewAllButton />
        </div>
      </div>
    </div>
  );
};

export default BookCategoryHeader;
