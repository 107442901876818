/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';

const coverImageWidth = isMobile ? '125px' : '195px';
const coverImageHeight = isMobile ? '188px' : '292px';

function BackgroundBookCover({ slug, rating, image }) {
  return (
    <Link to={slug}>
      <span
        className="absolute bg-gray-800 p-1 w-8 h-8 rounded-full flex justify-center items-center z-10"
        css={css`
          @media (min-width: 868px) {
            right: 5px;
            bottom: 20px;
          }
          right: -10px;
          bottom: -25px;
        `}
      >
        <span className="font-sans text-sm font-semibold text-white">{rating}</span>
      </span>
      <GatsbyImage
        image={image && image.childImageSharp.gatsbyImageData}
        className="rounded"
        alt="Book Cover"
        height={coverImageHeight}
        width={coverImageWidth}
      />
    </Link>
  );
}

export default function BookPostCard({ slug, rating, image }) {
  return (
    <div
      className="py-4 px-0 md:px-4 md:mr-0 mr-4 mb-4  relative"
      css={css`
        width: ${coverImageWidth};
        height: ${coverImageHeight};
      `}
    >
      <BackgroundBookCover slug={slug} rating={rating} image={image} />
    </div>
  );
}
