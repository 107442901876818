import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import BookCategoryHeader from '../components/template/BookCategoryHeader';
import BookPostCard from '../components/template/BookPostCard';

export default function BookCategoryTemplate({ data, pageContext }) {
  const { category } = pageContext;
  return (
    <Layout>
      {category && <BookCategoryHeader category={category} backLink="/books" />}
      <div className="container py-12">
        {' '}
        <div className="flex flex-wrap space-between justify-center">
          {data.books.nodes.map((book) => (
            <BookPostCard
              slug={book.frontmatter.slug}
              rating={book.frontmatter.rating}
              image={book.frontmatter.image}
              key={book.id}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($category: String) {
    books: allBook(
      sort: { fields: frontmatter___rating, order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
            }
          }
          slug
          readEase
          rating
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
`;
